
/******1024****/
$margin-value1024: 25px;

$grid-column-gap: 20px;
$grid-row-gap:7px;
html{
	@media screen and (max-width: 1600px) { 
		//font-size: ;
		font-size: $font-size-html-1600;
	}
	@media screen and (max-width: 1440px) { 
		//max-width: 1280px;
		font-size: $font-size-html-1440;
	}
	@media screen and (max-width: 1366px) { 
		//max-width: 1200px;
		font-size: $font-size-html-1366;
	
	}
	@media screen and (max-width: 1280px) { 
		//max-width: 1200px;
		font-size: $font-size-html-1280;
	
	}
	@media screen and (max-width: 1152px) { 
		//max-width: 1200px;
		font-size: $font-size-html-1152;
	}
	@media screen and (max-width: 1024px) { 
		//max-width: 1200px;
		font-size: $font-size-html-1024;
	}
	
	@media screen and (max-width: 640px) { 
		//max-width: 1200px;
		font-size: $font-size-html-640;
	}
	
	@media screen and (max-width: 414px) { 
		//max-width: 1200px;
		font-size: $font-size-html-414;
	}
}

#navbar .region-highlighted{
	@media screen and (min-width: 1367px) { 
		background-size: cover;
	}

}

.navbar.container > .container, .navbar.container-fluid > .container-fluid,
.container{
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	max-width: 100vw;
	
	@media screen and (max-width: 1153px ) { 
		max-width: 1072px!important;
	}
	
	@media screen and (max-width: 1025px ) { 
		max-width: 960px!important;
		width: 95vw!important;
	}
	@media screen and (max-width: 901px ) { 
		max-width: 850px!important;
	
	}
	
	@media screen and (max-width: 781px ) { 
		max-width: 718px!important;
	
	}
	
	@media screen and (max-width: 767px ) { 
		max-width: 718px!important;
	
	}
	
	@media screen and (max-width: 641px ) { 
		max-width: 600px!important;
	
	}
	
	@media screen and (max-width: 415px ) { 
		max-width: 394px!important;
	
	}
	@media screen and (max-width: 361px ) { 
		max-width: 330px!important;
	}

	
}


@media (min-aspect-ratio: 16/9) {
 
}
@media (max-aspect-ratio: 16/9) {
 
}
@media (orientation: portrait) {
	
	 
}

@media screen and (max-width: 1280px ) { 
	#block-block-2 .row{
		width: 90vw;
	}
	#block-block-3{
		height: auto;
		background-position: center center;
		padding-top: 30px; 
		&::before{
			left: -6vw;
		}
		.col-sm-6 p:first-of-type img{
			margin-bottom: 0;
		}	
		.col-sm-6 p:last-of-type{			
			@include font-size-rem(35);
			line-height: 45px;			
			.text-big{			
			@include font-size-rem(50);
			}
			padding-bottom: 30px;
		}
	}
	
	.front #block-system-main{
		background-position: center left;
		background-size: auto 100%;
		background-size: 50vw auto;
		.field-body{
	
			margin-top: 40px;
			margin-bottom: 40px;
		}
	

	}
	.front .field-body{
		$margin-l:35.87vw;
		margin-left: $margin-l;
		max-width: calc(100vw - #{$margin-l});
	}
}

@media screen and (max-width: 1024px ) { 

	
}	
@media screen and (max-width: 992px){
	.navbar-toggle {
	  display: block!important;
	}
	.navbar-collapse.collapse {
	  display: none!important;
	  &.in{
		display: block!important;
	  }
	}
	.navbar-default .navbar-toggle {
		border-color: $white;
		border-radius: 3px;
		padding: 8px 8px;
	}
	.navbar-default .navbar-toggle .icon-bar {
		//background-color: $gold;
		background-color: $white;
	}
	.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
		background-color: $beige;
		
	}
	.navbar-collapse {  
		width: 100vw;
	
	  .menu.navbar-nav{
		display: block;
		margin-top: 10px;
		border-top: 1px solid $beige;
		padding-top: 10px;
		padding-bottom: 20px;
		li{
			display: block;
			width: 100%;
			@media screen and (min-width: 768px) and (max-width: 992px){
				a{
					margin-left: 0!important;
					padding-left: 0!important;
		
					}
			}
			
		}
	  }
	}
  }
@media screen and (max-width: 940px ) { 
	
}	
@media screen and (max-width: 768px ) { 
	.footer,
	.main-container{
		overflow: hidden;
		width: 100vw;
		img{
			max-width: 100%;
		}

	}



}


/**********/

