#merci-staff-edit-form-wrapper{
	.form-item-override,
	.form-item-name{
		display: none;
	}
	
}
.calendar-calendar .month-view .full td.multi-day div.monthview, 
.calendar-calendar .week-view .full td.multi-day div.weekview, 
.calendar-calendar .day-view .full td.multi-day div.dayview{
		height: 2.9em !important;
}
#merci-build-reservation-table-form{
	thead{
		display: none!important;
	}
	tbody{
		td:last-child{
			display: none!important;
		}
	}

}
.node-unpublished{
	background: none;
}
.node-merci-reservation{
	.control-label,
	.field-label{
		font-weight: bold;
	}
	.control-label::after{
		content: ":";

	}
}
.logged-in:not(.front):not(.page-node) {
	.main-container {
		ol li:before, ul li:before{
			content: none;
			display: none;
			background: transparent;
		}
	}
	
}
.view-id-merci_calendar_view .view-header h3{
	font-size: 4rem;
	font-weight: bold;
}