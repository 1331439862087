@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
.color-gold {
  color: #C9A26B; }

.color-blue {
  color: #354671; }

.color-beige {
  color: #E8D2B2; }

.color-blue-light {
  color: #586A93; }

.grey-light {
  color: #EEEEEE !important; }

.grey-dark {
  color: #323232 !important; }

.bg-grey-light {
  background-color: #EEEEEE !important; }

.bg-grey {
  background-color: #323232 !important; }

.bg-yellow {
  background: linear-gradient(#ffcc01 0%, #d9a002 100%); }

html {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-family: "Oswald", sans-serif;
  font-size: 62.5%;
  font-weight: 300;
  box-sizing: border-box;
  vertical-align: baseline; }

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 300;
  color: #354671;
  box-sizing: border-box;
  vertical-align: baseline;
  line-height: 1.4em; }

span {
  color: inherit; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

a {
  text-decoration: none;
  outline: none; }

strong {
  font-size: inherit;
  font-weight: 600; }

a:hover,
a:focus {
  text-decoration: none;
  outline: none; }

button {
  outline: none; }

em {
  font-style: italic; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

#toolbar a {
  font-size: 1em; }

.top-bar {
  width: 100%;
  max-width: 100%; }

.navbar-default,
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0; }

.navbar.container, .navbar .container {
  margin-left: auto;
  margin-right: auto; }

.navbar.container-fluid {
  margin: 0; }

.container {
  padding: 0;
  box-sizing: border-box; }
  .container:before, .container:after {
    content: none; }

.navbar.container {
  margin: 0; }

* {
  box-sizing: border-box; }

.row {
  margin-right: 0;
  margin-left: 0; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  margin-left: 0;
  font-family: "Marcellus", serif; }

.footer {
  margin: 0px;
  padding: 0px;
  border-top: none; }

.form-control {
  display: block;
  width: 100%;
  padding: 2.5vh 2.1vw;
  font-size: 22px;
  line-height: 1.2em;
  color: #323232;
  background-color: #fff;
  background-image: none;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  border: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #5C627526;
  border-radius: 60px;
  opacity: 1;
  height: auto;
  min-height: 40px; }
  .form-control:hover, .form-control:focus {
    color: #323232;
    background-color: #EEEEEE;
    font-size: 22px; }

form .panel-body,
form .panel {
  margin-bottom: 0px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0; }

.btn.icon-before .glyphicon, .btn .glyphicon.icon-before {
  display: none; }

.btn:hover, .btn:focus, .btn:visited, .btn.focus, .btn-success:focus, .btn-success:hover {
  background-color: transparent;
  border: none; }

.btn-success {
  background-color: transparent;
  border: none; }

.dropdown-menu {
  border-radius: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.row {
  width: 100%; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

#tinymce::before,
.not-front #block-system-main::before {
  content: '';
  position: absolute;
  width: 426.08px;
  height: 437.48px;
  display: block;
  white-space: nowrap;
  background: url("/sites/all/themes/optimait/images/znak_gold.png") scroll no-repeat center center transparent;
  opacity: 0.15;
  left: -4.2vw;
  top: -17vmin; }

#tinymce h2,
.not-front #block-system-main h2 {
  font-size: 28px;
  font-size: 2.8rem; }

#tinymce h3,
.not-front #block-system-main h3 {
  font-size: 26px;
  font-size: 2.6rem; }

#tinymce h4,
.not-front #block-system-main h4 {
  font-size: 24px;
  font-size: 2.4rem; }

#tinymce h5,
.not-front #block-system-main h5 {
  font-size: 22px;
  font-size: 2.2rem; }

#tinymce ul li,
.not-front #block-system-main ul li {
  padding: 8px;
  line-height: 1.5em;
  padding-left: 20px; }
  #tinymce ul li::before,
  .not-front #block-system-main ul li::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 6px;
    display: block;
    white-space: nowrap;
    background: url("/sites/all/themes/optimait/images/pasek.jpg") scroll no-repeat center center transparent;
    transform-origin: top left;
    transform: rotate(90deg);
    background-size: 100% 100%;
    margin-left: -14px; }

#tinymce .box-person-wrapper,
.not-front #block-system-main .box-person-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row; }

#tinymce .box-person,
.not-front #block-system-main .box-person {
  width: calc(25% - 20px);
  background-color: #354671;
  background-color: #364772;
  color: #fff;
  margin: 10px;
  padding: 30px 5px;
  text-align: center;
  border-radius: 30px;
  line-height: 1.5em;
  cursor: default;
  font-size: 18px;
  font-size: 1.8rem;
  transition: all .5s ease-out;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5)); }
  #tinymce .box-person:nth-child(1),
  .not-front #block-system-main .box-person:nth-child(1) {
    background-color: #1e305c; }
  #tinymce .box-person:nth-child(2),
  .not-front #block-system-main .box-person:nth-child(2) {
    background-color: #253765; }
  #tinymce .box-person:nth-child(3),
  .not-front #block-system-main .box-person:nth-child(3) {
    background-color: #2d3f6c; }
  #tinymce .box-person:nth-child(4),
  .not-front #block-system-main .box-person:nth-child(4) {
    background-color: #364772; }
  #tinymce .box-person *,
  .not-front #block-system-main .box-person * {
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem; }
  #tinymce .box-person .name,
  .not-front #block-system-main .box-person .name {
    font-weight: 500; }
  #tinymce .box-person:focus, #tinymce .box-person:hover,
  .not-front #block-system-main .box-person:focus,
  .not-front #block-system-main .box-person:hover {
    transform: scale(1.02);
    transform-origin: center;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5)); }
    #tinymce .box-person:focus .name, #tinymce .box-person:hover .name,
    .not-front #block-system-main .box-person:focus .name,
    .not-front #block-system-main .box-person:hover .name {
      font-size: 19px;
      font-size: 1.9rem;
      filter: drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.2));
      font-weight: 600; }

strong {
  color: inherit; }

.page-header {
  border: none;
  margin-top: 50px !important; }

.navbar .logo {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0; }

.navbar .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  align-items: center; }
  .navbar .container .navbar-header {
    max-width: 80%; }
  .navbar .container .site-name-logo {
    max-width: 20%; }

.navbar-default .top-bar {
  position: fixed;
  z-index: 200;
  width: 100%;
  background-color: #354671; }
  .navbar-default .top-bar .navbar-collapse.collapse::after, .navbar-default .top-bar .navbar-collapse.collapse::before {
    content: none; }
  .navbar-default .top-bar .menu.navbar-nav {
    height: inherit;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between; }
    .navbar-default .top-bar .menu.navbar-nav::after, .navbar-default .top-bar .menu.navbar-nav::before {
      content: none; }
    .navbar-default .top-bar .menu.navbar-nav li:nth-child(3) a, .navbar-default .top-bar .menu.navbar-nav li:nth-child(2) a, .navbar-default .top-bar .menu.navbar-nav li:nth-child(1) a {
      color: #fff; }
    .navbar-default .top-bar .menu.navbar-nav li:nth-child(3) {
      margin-right: 50px; }
    .navbar-default .top-bar .menu.navbar-nav a {
      font-family: "Marcellus", serif;
      font-weight: normal;
      font-size: 18px;
      font-size: 18px;
      font-size: 1.8rem;
      text-transform: uppercase;
      color: #354671;
      height: inherit;
      line-height: inherit;
      padding: 0;
      margin-left: 10px;
      margin-right: 10px;
      color: #C9A26B;
      padding-left: 5px;
      padding-right: 5px; }
      .navbar-default .top-bar .menu.navbar-nav a:hover, .navbar-default .top-bar .menu.navbar-nav a:focus {
        background-color: #E8D2B2; }
    .navbar-default .top-bar .menu.navbar-nav li .active {
      background-color: #E8D2B2;
      font-weight: 600; }

/*****************strona***************************/
body.front {
  background-color: #354671; }

body {
  width: 100vw;
  overflow-x: hidden; }

#navbar {
  width: 100vmax;
  height: 84vmin; }
  #navbar .region-highlighted {
    top: 78px;
    position: relative; }
    #navbar .region-highlighted .podpis {
      position: absolute;
      right: 25px;
      color: #fff;
      right: 75px;
      bottom: 32px;
      z-index: 100;
      opacity: 0.8;
      font-weight: 600;
      text-transform: uppercase;
      width: auto;
      display: table;
      opacity: 0.5;
      font-size: 26px;
      font-size: 2.6rem;
      font-family: "Marcellus", serif;
      font-style: italic; }
    #navbar .region-highlighted * {
      width: 100%; }
    #navbar .region-highlighted #block-block-1 div {
      display: table; }
    #navbar .region-highlighted .bg_video {
      opacity: 1;
      width: 100%;
      object-fit: cover;
      object-position: center top;
      position: static;
      z-index: -1;
      right: 0;
      bottom: 0;
      min-width: 100%;
      max-width: 100vw;
      min-height: 100%;
      height: calc(84vmin - 78px);
      z-index: 0; }
    #navbar .region-highlighted:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      white-space: nowrap;
      background: radial-gradient(ellipse at center, rgba(53, 70, 113, 0) 0%, rgba(53, 70, 113, 0) 18.4%, rgba(53, 70, 113, 0.33) 28.44%, #354671 100%);
      filter: 0px;
      z-index: 1; }
    #navbar .region-highlighted .link-down {
      display: table;
      position: absolute;
      bottom: 30px;
      margin-right: auto;
      margin-left: auto;
      left: 0;
      right: 0;
      width: 62.5px;
      height: 36.55px;
      z-index: 10; }
      #navbar .region-highlighted .link-down a {
        width: 62.5px;
        height: 36.55px;
        background: url("/sites/all/themes/optimait/images/arrow.png") scroll no-repeat center center transparent;
        background-size: cover;
        display: block;
        opacity: 0.9;
        filter: drop-shadow(1px 1px 2px #000);
        position: relative; }
    #navbar .region-highlighted .text-slider {
      position: absolute;
      color: #fff;
      z-index: 5;
      width: 542px;
      top: 10vmin;
      left: 7%;
      text-align: center;
      font-size: 44px;
      font-size: 4.4rem;
      font-family: "Oswald", sans-serif;
      font-weight: normal;
      letter-spacing: 1px;
      line-height: 1.5em; }
      #navbar .region-highlighted .text-slider::before {
        content: '';
        position: absolute;
        width: 81px;
        height: 6px;
        display: block;
        white-space: nowrap;
        background: url("/sites/all/themes/optimait/images/pasek.jpg") scroll no-repeat center center transparent;
        left: 0;
        margin-top: 30%;
        margin-left: 50px; }
      #navbar .region-highlighted .text-slider::after {
        content: '';
        position: absolute;
        width: 81px;
        height: 6px;
        display: block;
        white-space: nowrap;
        background: url("/sites/all/themes/optimait/images/pasek.jpg") scroll no-repeat center center transparent;
        right: 0;
        margin-right: 50px;
        top: 0;
        margin-top: 30%; }
    #navbar .region-highlighted .text-uppercase {
      font-size: 44px;
      font-size: 4.4rem;
      font-family: "Oswald", sans-serif;
      font-weight: bold;
      letter-spacing: 0.5px; }

.view-aktualno-ci .page-header {
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-size: 1.8rem;
  border-bottom: none;
  font-family: "Marcellus", serif; }

.view-aktualno-ci .views-field-title {
  text-align: center; }

.view-aktualno-ci .views-row {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #E8D2B2; }

#block-block-2 {
  background-color: #fff;
  padding-top: 9vmin;
  padding-bottom: 9vmin;
  display: block;
  position: relative;
  z-index: 0;
  width: 100vw; }
  #block-block-2 h2 {
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    font-size: 35px;
    font-size: 35px;
    font-size: 3.5rem;
    letter-spacing: 0.05em;
    line-height: 85px;
    text-align: center;
    color: #354671;
    margin-bottom: 9vmin; }
  #block-block-2::before {
    content: '';
    position: absolute;
    width: 426.08px;
    height: 437.48px;
    display: block;
    white-space: nowrap;
    background: url("/sites/all/themes/optimait/images/znak_gold.png") scroll no-repeat center center transparent;
    opacity: 0.25;
    left: -4.2vw;
    top: -17vmin; }
  #block-block-2::after {
    content: '';
    position: absolute;
    width: 426.08px;
    height: 437.48px;
    display: block;
    white-space: nowrap;
    background: url("/sites/all/themes/optimait/images/znak_gold.png") scroll no-repeat center center transparent;
    opacity: 0.25;
    bottom: -10px;
    right: -14.34vw; }
  #block-block-2 .row {
    margin-left: auto;
    margin-right: auto;
    width: 65vmax;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row; }
    #block-block-2 .row .col-sm {
      width: 380px;
      padding-top: 60px;
      padding-bottom: 50px;
      background: #354671;
      text-align: center;
      position: relative; }
      #block-block-2 .row .col-sm .text-box2 {
        display: block;
        color: #fff;
        font-family: "Marcellus", serif;
        font-weight: normal;
        font-size: 29px;
        font-size: 29px;
        font-size: 2.9rem;
        letter-spacing: 0.05em;
        line-height: 35px;
        text-align: center;
        text-transform: uppercase;
        margin-top: 46px; }
        #block-block-2 .row .col-sm .text-box2::before {
          content: '';
          position: absolute;
          width: 111px;
          height: 6px;
          display: block;
          white-space: nowrap;
          margin-right: auto;
          margin-left: auto;
          left: 0;
          right: 0;
          background: url("/sites/all/themes/optimait/images/pasek.jpg") scroll no-repeat center center transparent;
          margin-top: 20px;
          margin-bottom: 20px;
          margin-top: -26px; }

#block-block-3 {
  background: url("/sites/all/themes/optimait/images/sekcja3.jpg") scroll no-repeat top center transparent;
  padding-top: 5vw;
  height: 31.99vw;
  position: relative;
  display: block;
  background-size: 100vw auto; }
  #block-block-3::before {
    content: '';
    position: absolute;
    width: 180px;
    height: 174px;
    display: block;
    white-space: nowrap;
    background: url("/sites/all/themes/optimait/images/znak_min.jpg") scroll no-repeat top left transparent;
    bottom: 0;
    left: 0; }
  #block-block-3 .col-sm-6 {
    text-align: center; }
    #block-block-3 .col-sm-6 img {
      margin-bottom: 20px; }
    #block-block-3 .col-sm-6 p {
      font-family: "Oswald", sans-serif;
      font-weight: 300;
      font-size: 40px;
      font-size: 40px;
      font-size: 4rem;
      line-height: 50px;
      text-align: center;
      color: #fff; }
      #block-block-3 .col-sm-6 p .text-big {
        font-weight: bold;
        font-size: 55px;
        font-size: 5.5rem; }

.region-pagesc {
  background: linear-gradient(to right, #fff 0%, #fff 50%, transparent 50%, transparent 100%);
  display: table;
  width: 100vw; }
  .region-pagesc #block-block-4 {
    margin-left: auto;
    margin-right: auto; }
    .region-pagesc #block-block-4 a {
      display: table; }
      .region-pagesc #block-block-4 a::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        display: block;
        white-space: nowrap;
        left: 0;
        background: url("/sites/all/themes/optimait/images/pasek.jpg") scroll repeat-y center center transparent;
        position: relative;
        background-size: 100% 6px;
        margin-top: -20px;
        margin-bottom: 15px; }
    .region-pagesc #block-block-4 .col-sm-6 {
      padding-top: 42px;
      padding-bottom: 42px; }
    .region-pagesc #block-block-4 .img-kontakt {
      margin-left: -5.27vw;
      width: 55.27vw; }
    .region-pagesc #block-block-4 .col-sm-6:nth-child(1) {
      padding-top: 45px;
      padding-bottom: 45px; }
      .region-pagesc #block-block-4 .col-sm-6:nth-child(1) a, .region-pagesc #block-block-4 .col-sm-6:nth-child(1) p {
        font-family: "Oswald", sans-serif;
        font-weight: normal;
        font-size: 22px;
        font-size: 2.2rem;
        line-height: 37px;
        text-align: left;
        color: #354671; }
      .region-pagesc #block-block-4 .col-sm-6:nth-child(1) p img {
        margin-bottom: 20px; }
      .region-pagesc #block-block-4 .col-sm-6:nth-child(1)::before {
        content: '';
        position: absolute;
        width: 426.08px;
        height: 437.48px;
        display: block;
        white-space: nowrap;
        background: url("/sites/all/themes/optimait/images/znak_blue.png") scroll no-repeat center center transparent;
        opacity: 1;
        right: -10vw;
        top: 25%; }

#block-block-5 {
  padding-top: 25px;
  padding-bottom: 25px; }
  #block-block-5 a, #block-block-5 p {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 16px;
    font-size: 1.6rem;
    text-align: right;
    color: #fff; }
  #block-block-5 .col-sm-6:nth-child(1) p {
    text-align: left; }
  #block-block-5 .col-sm-6:nth-child(2) {
    text-align: right; }

.col-region,
.footer {
  background-color: #354671; }

.footer {
  padding-bottom: 3%; }

.front .node {
  width: 100%; }

.front #block-system-main {
  background: url("/sites/all/themes/optimait/images/people.jpg") scroll no-repeat top left transparent;
  background-size: 35.73vw auto; }

.front .field-body {
  width: 100%;
  max-width: 68.45vw;
  background-color: #fff;
  padding: 65px 65px;
  margin-left: 26.87vw;
  margin-top: 86px;
  margin-bottom: 84px; }
  .front .field-body p {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 22px;
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 37px;
    text-align: left;
    padding-top: 56px; }
    .front .field-body p:first-of-type {
      padding-top: 10px; }
    .front .field-body p::before {
      content: '';
      position: absolute;
      width: 123px;
      height: 6px;
      display: block;
      white-space: nowrap;
      left: 0;
      background: url("/sites/all/themes/optimait/images/pasek.jpg") scroll repeat-y center center transparent;
      position: relative;
      background-size: 100% 6px;
      margin-top: -20px;
      margin-bottom: 15px; }
  .front .field-body strong {
    font-weight: normal; }

/*********************/
.not-front.page-node-10 .page-header {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase; }

#tinymce img {
  max-width: 100%;
  height: auto; }

.not-front .node .field-body img {
  max-width: 100%;
  height: auto; }

.not-front .row {
  margin-bottom: 30px; }

.not-front > .col-sm-12 {
  margin-bottom: 50px; }

.not-front .page-header {
  font-weight: bold;
  font-size: 35px;
  font-size: 28px;
  font-size: 2.8rem;
  letter-spacing: 0.05em;
  margin-bottom: 30px;
  margin-top: 50px;
  display: table; }
  .not-front .page-header::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 6px;
    display: block;
    white-space: nowrap;
    left: 0;
    background: url("/sites/all/themes/optimait/images/pasek.jpg") scroll repeat-y center center transparent;
    position: relative;
    background-size: 100% 6px;
    margin-bottom: -15px; }

.not-front #main-container p {
  line-height: 1.5em;
  margin-bottom: 0.6em; }

#imce-content .form-control, #imce-content .form-control {
  min-height: 40px;
  border-radius: 0; }

#imce-content .radio label, #imce-content .checkbox label, #imce-content .radio label, #imce-content .checkbox label {
  padding-left: 15px; }

#imce-content input[type="radio"], #imce-content input[type="checkbox"], #imce-content input[type="radio"], #imce-content input[type="checkbox"] {
  left: 0; }

#file-list td.width, #file-header td.width {
  width: 10em;
  text-align: right; }

/*********formularz*******************/
.webform-client-form-10 .webform-component {
  transition: all .5s ease-out; }

.webform-client-form-10 .control-label.checked-label {
  background-color: #203057 !important;
  filter: drop-shadow(1px 1px 2px #000); }

.webform-client-form-10 .form-required {
  display: none; }

.webform-client-form-10 .page-header {
  margin-left: auto !important;
  margin-right: auto !important; }

.webform-client-form-10 .form-item {
  text-align: center; }
  .webform-client-form-10 .form-item > .control-label {
    font-size: 30px;
    font-size: 3rem;
    text-align: center;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    padding-top: 60px;
    margin-bottom: 30px; }

.webform-client-form-10 .form-radios:not(.zgoda) {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: stretch; }
  .webform-client-form-10 .form-radios:not(.zgoda) .form-item {
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto; }
  .webform-client-form-10 .form-radios:not(.zgoda) .control-label {
    width: 270px;
    padding: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #354671;
    text-align: center;
    position: relative;
    font-family: "Marcellus", serif;
    font-weight: normal;
    font-size: 29px;
    font-size: 22px;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.4em;
    text-align: center;
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row; }
    .webform-client-form-10 .form-radios:not(.zgoda) .control-label:focus, .webform-client-form-10 .form-radios:not(.zgoda) .control-label:hover {
      background-color: #586A93; }
    .webform-client-form-10 .form-radios:not(.zgoda) .control-label input {
      display: none; }
    .webform-client-form-10 .form-radios:not(.zgoda) .control-label::before {
      content: '';
      position: absolute;
      width: 81px;
      height: 6px;
      display: block;
      white-space: nowrap;
      background: url("/sites/all/themes/optimait/images/pasek.jpg") scroll no-repeat center top transparent;
      left: 0;
      top: 0;
      margin-top: 0;
      margin-left: 50px;
      margin-right: auto;
      margin-left: auto;
      left: 0;
      right: 0; }

.webform-client-form-10 .form-item-submitted-pozyczka .form-item .control-label {
  width: 250px;
  padding-top: 170px;
  padding-bottom: 25px;
  margin-bottom: 0; }
  .webform-client-form-10 .form-item-submitted-pozyczka .form-item .control-label::before {
    margin-top: calc(170px - 18px); }

.webform-client-form-10 .form-item-submitted-pozyczka .form-item:nth-child(1) .control-label {
  background: url("/sites/all/themes/optimait/images/firma.jpg") no-repeat scroll center 30px #354671; }

.webform-client-form-10 .form-item-submitted-pozyczka .form-item:nth-child(2) .control-label {
  background: url("/sites/all/themes/optimait/images/traktor.jpg") no-repeat scroll center 30px #354671; }

.webform-client-form-10 .webform-component--wysokosc-twoich-obrotow-rocznych {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px; }

.webform-client-form-10 .form-actions {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row; }
  .webform-client-form-10 .form-actions .webform-submit {
    width: 270px;
    padding: 30px;
    padding-top: 30px;
    padding-left: 0;
    padding-bottom: 30px;
    background: #354671;
    text-align: center;
    position: relative;
    font-family: "Marcellus", serif;
    font-weight: normal;
    font-size: 29px;
    font-size: 22px;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.4em;
    text-align: center;
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 0;
    position: relative; }
    .webform-client-form-10 .form-actions .webform-submit::after {
      content: '';
      position: absolute;
      width: 62.5px;
      height: 36.55px;
      display: block;
      white-space: nowrap;
      background: url("/sites/all/themes/optimait/images/arrow.png") scroll no-repeat right center transparent;
      background-size: cover;
      display: block;
      opacity: 0.9;
      filter: drop-shadow(1px 1px 2px #000);
      transform: rotate(-90deg) scale(0.7);
      right: 0;
      top: 0;
      margin-right: 10%;
      margin-top: 10%; }

.webform-client-form-10 .form-control {
  border-radius: 0; }

.webform-client-form-10 .form-control:hover, .webform-client-form-10 .form-control:focus {
  background-color: #f3f3f3; }

.webform-client-form-10 .panel-body .control-label {
  padding-top: 10px; }

.webform-client-form-10 .panel-body .grippie {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }

.webform-client-form-10 .panel-body .form-control {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px; }

.webform-client-form-10 .panel-body .zgoda .control-label {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 300;
  max-width: 500px;
  text-align: left; }

.card {
  width: 95%; }
  .card .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #354671;
    border-bottom: 1px solid #354671;
    color: #fff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    z-index: 1;
    position: relative; }

.node-page .col-sm-12 {
  width: 100%; }

.nav-tabs {
  position: relative;
  z-index: 100; }

.tooltip-box {
  background-color: #333333;
  color: #fff;
  position: absolute;
  top: -50px;
  left: -20px !important;
  right: -20px;
  width: calc(100% + 40px);
  margin-left: auto;
  margin-right: auto;
  font-family: "Oswald", sans-serif !important;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  font-size: 1.4rem;
  left: 0;
  padding: 10px 10px;
  z-index: 5; }

#cookie-rp {
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  z-index: 20;
  position: fixed; }

#cookie-rp #cookie-close-rp {
  float: right;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-size: 1.4rem; }

#cookie-rp .cookie-text {
  max-width: 90%;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem; }

#cookie-rp .cookie-text a {
  color: #fff;
  font-size: inherit; }

#merci-staff-edit-form-wrapper .form-item-override,
#merci-staff-edit-form-wrapper .form-item-name {
  display: none; }

.calendar-calendar .month-view .full td.multi-day div.monthview,
.calendar-calendar .week-view .full td.multi-day div.weekview,
.calendar-calendar .day-view .full td.multi-day div.dayview {
  height: 2.9em !important; }

#merci-build-reservation-table-form thead {
  display: none !important; }

#merci-build-reservation-table-form tbody td:last-child {
  display: none !important; }

.node-unpublished {
  background: none; }

.node-merci-reservation .control-label,
.node-merci-reservation .field-label {
  font-weight: bold; }

.node-merci-reservation .control-label::after {
  content: ":"; }

.logged-in:not(.front):not(.page-node) .main-container ol li:before, .logged-in:not(.front):not(.page-node) .main-container ul li:before {
  content: none;
  display: none;
  background: transparent; }

.view-id-merci_calendar_view .view-header h3 {
  font-size: 4rem;
  font-weight: bold; }

/******1024****/
@media screen and (max-width: 1600px) {
  html {
    font-size: 60.5%; } }

@media screen and (max-width: 1440px) {
  html {
    font-size: 58.5%; } }

@media screen and (max-width: 1366px) {
  html {
    font-size: 56.5%; } }

@media screen and (max-width: 1280px) {
  html {
    font-size: 54.5%; } }

@media screen and (max-width: 1152px) {
  html {
    font-size: 52.5%; } }

@media screen and (max-width: 1024px) {
  html {
    font-size: 50.5%; } }

@media screen and (max-width: 640px) {
  html {
    font-size: 49.5%; } }

@media screen and (max-width: 414px) {
  html {
    font-size: 47.5%; } }

@media screen and (min-width: 1367px) {
  #navbar .region-highlighted {
    background-size: cover; } }

.navbar.container > .container, .navbar.container-fluid > .container-fluid,
.container {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 100vw; }
  @media screen and (max-width: 1153px) {
    .navbar.container > .container, .navbar.container-fluid > .container-fluid,
    .container {
      max-width: 1072px !important; } }
  @media screen and (max-width: 1025px) {
    .navbar.container > .container, .navbar.container-fluid > .container-fluid,
    .container {
      max-width: 960px !important;
      width: 95vw !important; } }
  @media screen and (max-width: 901px) {
    .navbar.container > .container, .navbar.container-fluid > .container-fluid,
    .container {
      max-width: 850px !important; } }
  @media screen and (max-width: 781px) {
    .navbar.container > .container, .navbar.container-fluid > .container-fluid,
    .container {
      max-width: 718px !important; } }
  @media screen and (max-width: 767px) {
    .navbar.container > .container, .navbar.container-fluid > .container-fluid,
    .container {
      max-width: 718px !important; } }
  @media screen and (max-width: 641px) {
    .navbar.container > .container, .navbar.container-fluid > .container-fluid,
    .container {
      max-width: 600px !important; } }
  @media screen and (max-width: 415px) {
    .navbar.container > .container, .navbar.container-fluid > .container-fluid,
    .container {
      max-width: 394px !important; } }
  @media screen and (max-width: 361px) {
    .navbar.container > .container, .navbar.container-fluid > .container-fluid,
    .container {
      max-width: 330px !important; } }

@media screen and (max-width: 1280px) {
  #block-block-2 .row {
    width: 90vw; }
  #block-block-3 {
    height: auto;
    background-position: center center;
    padding-top: 30px; }
    #block-block-3::before {
      left: -6vw; }
    #block-block-3 .col-sm-6 p:first-of-type img {
      margin-bottom: 0; }
    #block-block-3 .col-sm-6 p:last-of-type {
      font-size: 35px;
      font-size: 3.5rem;
      line-height: 45px;
      padding-bottom: 30px; }
      #block-block-3 .col-sm-6 p:last-of-type .text-big {
        font-size: 50px;
        font-size: 5rem; }
  .front #block-system-main {
    background-position: center left;
    background-size: auto 100%;
    background-size: 50vw auto; }
    .front #block-system-main .field-body {
      margin-top: 40px;
      margin-bottom: 40px; }
  .front .field-body {
    margin-left: 35.87vw;
    max-width: calc(100vw - 35.87vw); } }

@media screen and (max-width: 992px) {
  .navbar-toggle {
    display: block !important; }
  .navbar-collapse.collapse {
    display: none !important; }
    .navbar-collapse.collapse.in {
      display: block !important; }
  .navbar-default .navbar-toggle {
    border-color: #FFFFFF;
    border-radius: 3px;
    padding: 8px 8px; }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #FFFFFF; }
  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: #E8D2B2; }
  .navbar-collapse {
    width: 100vw; }
    .navbar-collapse .menu.navbar-nav {
      display: block;
      margin-top: 10px;
      border-top: 1px solid #E8D2B2;
      padding-top: 10px;
      padding-bottom: 20px; }
      .navbar-collapse .menu.navbar-nav li {
        display: block;
        width: 100%; } }
      @media screen and (max-width: 992px) and (min-width: 768px) and (max-width: 992px) {
        .navbar-collapse .menu.navbar-nav li a {
          margin-left: 0 !important;
          padding-left: 0 !important; } }

@media screen and (max-width: 768px) {
  .footer,
  .main-container {
    overflow: hidden;
    width: 100vw; }
    .footer img,
    .main-container img {
      max-width: 100%; } }

/**********/
/***************keyframes*******************/
