html{
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
	
	font-family: $font-family;
	font-size: $font-size-html;
	font-weight:300;

    // Sposób wyliczania wysokości i szerokości elementu.
    // Szerokość elementu np 25% zawiera już paddingi oraz border.
    box-sizing: border-box;

    vertical-align: baseline;
}
body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, input {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
	
	@include font-size-rem(20);
    font-weight:300;
    color: $blue;

    // Sposób wyliczania wysokości i szerokości elementu.
    // Szerokość elementu np 25% zawiera już paddingi oraz border.
    box-sizing: border-box;

    vertical-align: baseline; 
    line-height: 1.4em;
}

span{
    color: inherit;
}    

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block; }

a {
    text-decoration: none;
    outline: none;
}
strong{
    font-size: inherit;
    font-weight: 600;
}
a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

button {
    outline: none;
}
em{
    font-style: italic;
}
ol, ul {
    list-style: none; }

blockquote, q {
    quotes: none; }

blockquote {
    &:before, &:after {
        content: '';
        content: none; } }

q {
    &:before, &:after {
        content: '';
        content: none; } }

table {
    border-collapse: collapse;
    border-spacing: 0; }

    #toolbar a{
        font-size: 1em;;
    }    