@import "pack/variables";
@import "pack/reset";
@import "pack/resetBootsrap";
@import "pack/aos"; 



@mixin before-after-element ($width, $height){
	content:'';
	position:absolute;
	width:$width;
	height:$height;
	display:block;
	white-space: nowrap;
	
}	
	
@mixin transition3ease-out-site{
	transition: all .5s ease-out;
}

@mixin position-margin ($top, $bottom, $left, $right){
	position: relative;
	margin-top:$top;
	margin-bottom:$bottom;
	margin-left:$left;
	margin-right:$right;
}
@mixin position ($top, $bottom, $left, $right){
	position: relative;
	top:$top;
	bottom:$bottom;
	left:$left;
	right:$right;
}
@mixin position-reset {
	position: relative;
	top:auto;
	bottom:auto;
	left:auto;
	right:auto;
}
@mixin position-absolut-center-horizontal {
	margin-right:auto;
	margin-left:auto;
	left:0;
	right:0;
}
@mixin position-absolut-center-vertical {
	margin-top:auto;
	margin-bottom:auto;
	top:0;
	bottom:0;
}

@mixin flex-site-center{
	display:flex;
	flex-wrap:wrap;		
	justify-content:center;
	align-items:center;
	flex-direction: row;
}
@mixin flex-site{
	display:flex;
	justify-content: space-between;
	flex-wrap:wrap;
	align-items: flex-start;
	flex-direction: row;
}
@mixin animation-parm($name, $time, $ease, $delay, $i, $direction){
	animation-name: $name;
	animation-duration: $time;
	animation-timing-function: $ease;
	animation-delay: $delay;
	animation-iteration-count: $i;
	animation-direction: $direction;
}

@mixin animation-3s-ease-out($name){
	@include  animation-parm($name, 3s,  ease-out, 0s, 1, normal);
}


@mixin dots($dot-color, $bg-color, $dot-space, $dot-size) {
	/*background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;*/

	background-image: radial-gradient($dot-color ($dot-space - $dot-size), transparent 1%);
	background-color: $bg-color;
	background-repeat: repeat;
	background-position: top left;
	background-size: ($dot-space + $dot-size)  ($dot-space + $dot-size)  ;
}
@mixin style-h1{
	font-family: $font-family-h;
		font-weight: normal;
		font-size: 52px;
		@include font-size-rem(52);
		line-height: 0.75em;
		text-align: center;
		color: $blue;
		margin-top: 45px;
			padding-bottom: 20px;
			margin-bottom:22px;
			position: relative;
		&:before{
			@include before-after-element(101px,  0.5px);
			background: $blue;
			@include position-absolut-center-horizontal;
			bottom: 0;
			opacity: 0.25;
		}
}
#tinymce,
.not-front #block-system-main{
	
	&::before{
		@include before-after-element(426.08px, 437.48px);
		background: url('/sites/all/themes/optimait/images/znak_gold.png') scroll no-repeat center center transparent;
		opacity: 0.15;
		left: -4.2vw;
		top:-17vmin;
	}
	 h2{
			@include font-size-rem(28);
		}
	h3{
		@include font-size-rem(26);
	}
	h4{	
		@include font-size-rem(24);
	}
	h5{	
		@include font-size-rem(22);
	}
	ul li{
		padding: 8px;
		line-height: 1.5em;
		padding-left: 20px;

		&::before{
			@include before-after-element(25px, 6px);
			background: url('/sites/all/themes/optimait/images/pasek.jpg') scroll no-repeat center center transparent;
			transform-origin: top left;
			transform: rotate(90deg);
			background-size: 100% 100%;
			margin-left: -14px;
		}
		

	}
	.box-person-wrapper{
		@include flex-site();
	}
	.box-person{
		width: calc(25% - 20px);
		background-color: $blue;
		background-color: hsl(223, 36%, 33%);
		&:nth-child(1){	background-color: hsl(223, 51%, 24%);}
		&:nth-child(2){	background-color: hsl(223, 46%, 27%);}
		&:nth-child(3){	background-color: hsl(223, 41%, 30%);}
		&:nth-child(4){	background-color: hsl(223, 36%, 33%);}	
		color: #fff;
		margin: 10px;
		padding: 30px 5px;
		text-align: center;
		border-radius: 30px;
		line-height: 1.5em;
		cursor: default;
		@include font-size-rem(18);
		@include transition3ease-out-site();
		filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
		* {
			color: #fff;
			@include font-size-rem(18);
		}
		.name{
			font-weight: 500;
		}
		&:focus,
		&:hover{
			transform: scale(1.02);
			transform-origin: center;
			filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
			.name{				
				@include font-size-rem(19);
				filter: drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.2));
				font-weight: 600;
			}

		}
	}

}

 

strong{
	color: inherit;
}
.page-header{
	border: none;
	margin-top: 50px!important;
}
.navbar .logo{
	display: block;
	padding-top: 10px;
	padding-bottom: 10px;
	margin: 0;
}
.navbar .container{
	@include flex-site();
	align-items: center;
	.navbar-header{
		max-width: 80%;
	}
	.site-name-logo{
		max-width: 20%;	
	}
}
.navbar-default .top-bar{
	position: fixed;
	z-index: 200;
	width:100%;
	background-color: $blue;

	.navbar-collapse.collapse{
		&::after,
		&::before{
			content: none;
		}
	}
	.menu.navbar-nav{
		height: inherit;
		width: 100%;
		@include flex-site();
		justify-content: space-between;
		&::after,
		&::before{
			content: none;
		}

		li{
			

			&:nth-child(3),
			&:nth-child(2),
			&:nth-child(1){
				a{
				color: #fff;
	
				}
			}
			
			&:nth-child(3){
				margin-right: 50px;
			}
		}
	
		a{
			font-family: $font-family-h;
			font-weight: normal;
			font-size: 18px;
			@include font-size-rem(18);
			text-transform: uppercase;
			color: $blue;
			height: inherit;
			line-height: inherit;
			padding: 0;
			margin-left: 10px;
			margin-right: 10px;
			color: $gold;
			padding-left: 5px;
			padding-right: 5px;

			&:hover,
			&:focus{
				background-color: $beige;
			}
		}
	
		li{
			.active{
				background-color: $beige;
				font-weight: 600;
			}
		}
	}


}

/*****************strona***************************/
body.front{
	background-color: $blue;
}
body{	
	width: 100vw;
	overflow-x: hidden;
}

#navbar{
	width: 100vmax; 
	height: 84vmin;

	.region-highlighted{ 
		.podpis{
			position: absolute;
			right: 25px;
			color: #fff;  
			right: 75px;
			bottom: 32px;
			z-index: 100;
			opacity: 0.8;
			font-weight: 600;
			text-transform: uppercase;
			width: auto;
			display: table;
			opacity: 0.5;
			@include font-size-rem(26);
			font-family: $font-family-h;
			font-style: italic;
		} 
		top:78px;
		position: relative;
		& * {
			width: 100%;
		}
		#block-block-1  div{
			display: table;

		}
		.bg_video{
			opacity: 1;
			width: 100%;
			object-fit: cover;
			object-position: center top;
			position: static;
			z-index: -1;
			right: 0;
			bottom: 0;
			min-width: 100%;
			max-width: 100vw;
			min-height: 100%;
			height: calc(84vmin - 78px);
			z-index: 0;
		}

		&:before{
			@include before-after-element(100%, 100%);
			background:  radial-gradient(ellipse at center, rgba(53, 70, 113, 0) 0%, rgba(53, 70, 113, 0) 18.4%, rgba(53, 70, 113, 0.33) 28.44%, #354671 100%);
			filter: 0px;
			z-index: 1;
		}
		.link-down{
			display: table;
			position: absolute;
			bottom: 30px;
			@include position-absolut-center-horizontal();	
			width: 62.5px;
				height: 36.55px;
				
				z-index: 10;
				
			a{
				width: 62.5px;
				height: 36.55px;
				background: url('/sites/all/themes/optimait/images/arrow.png') scroll no-repeat center center transparent;
				background-size: cover;
				display: block;
				opacity: 0.9;
				filter: drop-shadow(1px 1px 2px #000);
				position: relative;
			}			
		}
		.text-slider{
			position: absolute;
			color: #fff;
			z-index: 5;
			width: 542px;
			top:10vmin;
			left:7%;
			text-align: center;
			@include font-size-rem(44);
			font-family: $font-family;
			font-weight: normal;
			letter-spacing: 1px;
			line-height: 1.5em;
			&::before{
				@include before-after-element(81px, 6px);
				background: url('/sites/all/themes/optimait/images/pasek.jpg') scroll no-repeat center center transparent;
				left: 0;
				margin-top:30%;
				margin-left: 50px;
	
			}
			&::after{
				@include before-after-element(81px, 6px);
				background: url('/sites/all/themes/optimait/images/pasek.jpg') scroll no-repeat center center transparent;
				right: 0;
				margin-right: 50px;
				top: 0;
				margin-top:30%;
	
			}


		}
		.text-uppercase{
			@include font-size-rem(44);
			font-family: $font-family;
			font-weight: bold;
			letter-spacing: 0.5px;

		}

	}
}	
.view-aktualno-ci {
	.page-header{
		margin-left: auto;
		margin-right: auto;
		@include font-size-rem(18);
		border-bottom: none;
		font-family: $font-family-h;
	}
	.views-field-title{
		text-align: center;
	}
	.views-row{
		margin-bottom: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid $beige;
	}
}
#block-block-2{
	background-color: #fff;
	padding-top: 9vmin;
	padding-bottom: 9vmin;
	display: block;
	position: relative;
	z-index: 0;
	width: 100vw;
	h2{
		font-family: $font-family;
		font-weight: bold;
		font-size: 35px;
		@include font-size-rem(35);
		letter-spacing: 0.05em;
		line-height: 85px;
		text-align: center;
		color: #354671;
		margin-bottom: 9vmin;
	}
	&::before{
		@include before-after-element(426.08px, 437.48px);
		background: url('/sites/all/themes/optimait/images/znak_gold.png') scroll no-repeat center center transparent;
		opacity: 0.25;
		left: -4.2vw;
		top:-17vmin;
	}
	&::after{
		@include before-after-element(426.08px, 437.48px);
		background: url('/sites/all/themes/optimait/images/znak_gold.png') scroll no-repeat center center transparent;
		opacity: 0.25;
		bottom:-10px;
		right: -14.34vw;

	}
	.row{
		margin-left: auto;
		margin-right: auto;
		width: 65vmax;
		@include flex-site();
		.col-sm{
			width: 380px;
			padding-top:60px;
			padding-bottom:50px;
			background: $blue;
			text-align: center;
			position: relative;
			.text-box2{
				display: block;
				color: #fff;
				font-family: $font-family-h;
				font-weight: normal;
				font-size: 29px;
				@include font-size-rem(29);
				letter-spacing: 0.05em;
				line-height: 35px;
				text-align: center;
				text-transform: uppercase;
				margin-top: 46px;
				&::before{
					@include before-after-element(111px, 6px);
					@include position-absolut-center-horizontal; 
					background: url('/sites/all/themes/optimait/images/pasek.jpg') scroll no-repeat center center transparent;
				
					//background: linear-gradient(#c9a26b 0%, #e8d2b2 49.2%, #c9a26b 100%);
					margin-top: 20px;
					margin-bottom: 20px;
					margin-top:-26px;
		
				}
			}

		}
	}

}

#block-block-3{
	background: url('/sites/all/themes/optimait/images/sekcja3.jpg') scroll no-repeat top center transparent;
	padding-top: 5vw;
	height: 31.99vw;
	position: relative;
	display: block;
	background-size: 100vw auto;
	&::before{
		@include before-after-element(180px, 174px);
		background: url('/sites/all/themes/optimait/images/znak_min.jpg') scroll no-repeat top left transparent;
	

		bottom: 0;
		left: 0;

	}
	.col-sm-6{
		text-align: center;
		img{
			margin-bottom: 20px;
		}
		p{
			font-family: $font-family;
			font-weight: 300;
			font-size: 40px;
			@include font-size-rem(40);
			line-height: 50px;
			text-align: center;
			color: #fff;
			.text-big{
				
			font-weight: bold;
			
			@include font-size-rem(55);
			}

		}
		
	}
	

}
.region-pagesc{
	background: linear-gradient(to right, #fff 0%, #fff 50%, transparent 50%, transparent 100%);
	display: table;
	width: 100vw;
	
	#block-block-4{
		
	margin-left: auto;
	margin-right: auto;

		a{
			display: table;
			&::before{
				@include before-after-element(100%, 6px);
				left: 0;
				background: url('/sites/all/themes/optimait/images/pasek.jpg') scroll repeat-y center center transparent;
				position: relative;
				background-size: 100% 6px;
				margin-top: -20px;
				margin-bottom: 15px;
			}
		}

		.col-sm-6{
			padding-top: 42px;
			padding-bottom: 42px;
		}	
		.img-kontakt{
			margin-left:-5.27vw;
			width: 55.27vw; 

			
		}
		.col-sm-6:nth-child(1){
			padding-top: 45px;
			padding-bottom: 45px;
			a,p{
				font-family:  $font-family;
				font-weight: normal;
				@include font-size-rem(22);
				line-height: 37px;
				text-align: left;
				color: #354671;

			}
			p img{
				margin-bottom: 20px;

			}
			&::before{
				@include before-after-element(426.08px, 437.48px);
				background: url('/sites/all/themes/optimait/images/znak_blue.png') scroll no-repeat center center transparent;
				opacity: 1;
				right: -10vw;
				top:25%;
			}
		}
	}
}

#block-block-5{
	padding-top: 25px;;
	padding-bottom: 25px;;
	a,p{
		font-family: $font-family;
		font-weight: 300;
		@include font-size-rem(16);
		text-align: right;
		color: #fff;

	}
	.col-sm-6:nth-child(1) p{
		text-align: left;

	}
	.col-sm-6:nth-child(2){
		text-align: right;

	}
}
.col-region,
.footer{
	background-color: $blue;
}
.footer{	
	padding-bottom: 3%;
}
.front{
	.node{
		width: 100%;
	}
	#block-system-main{
		background:  url('/sites/all/themes/optimait/images/people.jpg') scroll no-repeat top left transparent;
		background-size:35.73vw auto;
	}
	.field-body{
		width: 100%;
		max-width: 68.45vw;
		background-color:#fff;
		padding: 65px 65px;
		margin-left: 26.87vw;
		margin-top: 86px;
		margin-bottom: 84px;

	
		p{
			font-family: $font-family;
			font-weight: 300;
			font-size: 22px;
			@include font-size-rem(22);
			line-height: 37px;
			text-align: left;
			padding-top: 56px;
			&:first-of-type{
				padding-top: 10px;
			}

			&::before{
				@include before-after-element(123px, 6px);
				left: 0;
				background: url('/sites/all/themes/optimait/images/pasek.jpg') scroll repeat-y center center transparent;
				position: relative;
				background-size: 100% 6px;
				margin-top: -20px;
				margin-bottom: 15px;

	
			}
			
		}
		strong{
			font-weight: normal; 
		}


	}
}
#block-slajder{
	//background: url("../images/podzlotymzaglem.webp") no-repeat scroll center center transparent;
}
/*********************/
.not-front.page-node-10{
	.page-header{
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		text-transform: uppercase;
	}
}
#tinymce{
	img{
		max-width: 100%;
		height: auto;
	}

}
.not-front{
	.node .field-body img{
		max-width: 100%;
		height: auto;
	}
	.row{
		margin-bottom: 30px;
	}
	$margin-top-bottom:50px;
	& > .col-sm-12{
		margin-bottom: $margin-top-bottom; 
	}
	.page-header{
		font-weight: bold;
		font-size: 35px;
		@include font-size-rem(28);
		letter-spacing: 0.05em;
		margin-bottom: 30px;
		margin-top: $margin-top-bottom;
		display: table;
		&::after{
			@include before-after-element(100%, 6px);
			left: 0;
			background: url('/sites/all/themes/optimait/images/pasek.jpg') scroll repeat-y center center transparent;
			position: relative;
			background-size: 100% 6px;			
			margin-bottom: -15px;
		}
		

	}
	#main-container{
		p{
			line-height: 1.5em;
			margin-bottom: 0.6em;	
		}
	}
	
}
#imce-content, #imce-content{
	.form-control{
	min-height: 40px;
	border-radius: 0;

	}
	.radio label, .checkbox label{
		padding-left: 15px;
		
	}
	input[type="radio"], input[type="checkbox"] {
		left: 0;
	}
}
#file-list td.width, #file-header td.width {
    width: 10em;
    text-align: right;
}
/*********formularz*******************/
.webform-client-form-10{ 
	.webform-component{
		@include transition3ease-out-site();
	}
	.control-label.checked-label{
		background-color: #203057 !important;
		filter: drop-shadow(1px 1px 2px #000); 
	}
	.form-required{
		display: none;
	}
	.page-header {
		margin-left: auto!important;
		margin-right: auto!important;
	}
	.form-item{
		text-align: center;
		& > .control-label{
			@include font-size-rem(30);
			text-align: center;
			font-weight: 600;
			font-family: $font-family;
			padding-top: 60px;
			margin-bottom: 30px;

		}
	}
	.form-radios:not(.zgoda) {
		@include flex-site-center();
		align-items: stretch;
		.form-item{
			margin-top: 10px;
			margin-bottom: 10px;
			height: auto;
			
		}
	
		.control-label{
			
				width: 270px;
				padding: 30px;
				padding-top:30px;
				padding-bottom:30px;
				background: $blue; 
				text-align: center;
				position: relative;
				font-family: $font-family-h;
				font-weight: normal;
				font-size: 29px;
				@include font-size-rem(22);
				text-transform: uppercase;
				letter-spacing: 0.05em;
				line-height: 1.4em;
				text-align: center;
				color: #fff;
				margin-left: 20px;
				margin-right: 20px;
				height: 100%;
				@include flex-site-center();

				&:focus,
				&:hover{
					background-color: $blue-light;
				}

				input{
					display: none;
				}

				&::before{
					@include before-after-element(81px, 6px);
					background: url('/sites/all/themes/optimait/images/pasek.jpg') scroll no-repeat center top transparent;
					left: 0;
					top:0;
					margin-top:0;
					margin-left: 50px;
					@include position-absolut-center-horizontal();
				} 

		}
	}
	$padding-top: 170px;
	$padding-bottom: 25px;
	$margin-top: calc(#{$padding-top} - 18px);  
	.form-item-submitted-pozyczka{
		.form-item{
			.control-label{  
				width: 250px;
				padding-top: $padding-top;
				padding-bottom: $padding-bottom;
				&::before{
					margin-top: $margin-top;
				}
				margin-bottom: 0;
			}
		}
		.form-item:nth-child(1){
			.control-label{ 
				background:  url('/sites/all/themes/optimait/images/firma.jpg') no-repeat scroll center 30px $blue;
				
			}

		}
		.form-item:nth-child(2){
			.control-label{ 
				background:  url('/sites/all/themes/optimait/images/traktor.jpg') no-repeat scroll center 30px $blue;
		

			}

		}
	}
	.webform-component--wysokosc-twoich-obrotow-rocznych{
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 10px;
		
	}
	
	.form-actions{
		
		margin-top: 50px;
		margin-bottom: 50px;
		@include flex-site-center();
		.webform-submit{
			width: 270px;
			padding: 30px;
			padding-top:30px;
			padding-left: 0;
			padding-bottom:30px;
			background: $blue; 
			text-align: center;
			position: relative;
			font-family: $font-family-h;
			font-weight: normal;
			font-size: 29px;
			@include font-size-rem(22);
			text-transform: uppercase;
			letter-spacing: 0.05em;
			line-height: 1.4em;
			text-align: center;
			color: #fff;
			margin-left: 20px;
			margin-right: 20px;
			height: 100%;
			margin-right: auto;
			margin-left: auto;
			border-radius: 0;
			position: relative;
			&::after{
				@include before-after-element(62.5px,36.55px);		
					background: url('/sites/all/themes/optimait/images/arrow.png') scroll no-repeat right center transparent;
					background-size: cover;
					display: block;
					opacity: 0.9;
					filter: drop-shadow(1px 1px 2px #000);
					transform: rotate(-90deg) scale(0.7);
					right: 0;
					top: 0;
					margin-right: 10%;
					margin-top: 10%;
			}
		}
	}
	.form-control{
		border-radius: 0;
	}
	.form-control:hover, .form-control:focus{
		background-color: #f3f3f3;
	}
	.panel-body{
		.control-label{
			padding-top: 10px;
		}
		.grippie{
			max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		}
		.form-control{
			max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 10px;
		}
		.zgoda{
			.control-label{
				@include font-size-rem(14);
				font-weight: 300;
				max-width: 500px;
				text-align: left;
			
			}
		}
	}
}
.card{
	width: 95%;
	.card-header {
		padding: .75rem 1.25rem;
		margin-bottom: 0;
		background-color: $blue;
		border-bottom: 1px solid $blue;
		color: #fff;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		z-index: 1;
		position: relative;
	}
}
.node-page{
	.kontakt{
		
	}
	.col-sm-12{
		width: 100%;
	}
}

.nav-tabs{
	position: relative;
	z-index: 100;
}
.tooltip-box{
	background-color: #333333;
	color: #fff;
	position: absolute;
	top:-50px;
	left:-20px !important;
	right:-20px;
	width: calc(100% + 40px);
	margin-left: auto;
	margin-right: auto;
	font-family: $font-family!important;
	font-weight: 400;
	text-transform: none;
	@include font-size-rem(14);
	left: 0;
	padding: 10px 10px;
	z-index: 5;


}
#cookie-rp {
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    z-index: 20;
    position: fixed;
}
#cookie-rp #cookie-close-rp {
    float: right;
    color: #fff;
    cursor: pointer;
	@include font-size-rem(14);
}
#cookie-rp .cookie-text {
    max-width: 90%;
    color: #fff;
	@include font-size-rem(14);
}
#cookie-rp .cookie-text a {
    color: #fff;
	font-size: inherit;
}
@import "pack/admin";
@import "pack/responsive";
/***************keyframes*******************/
