@mixin font-size-rem($sizeValue: 10) {
	font-size: $sizeValue + px;
    font-size: ($sizeValue / $font-size-html-px) + rem;    
  }

  @function rem($sizeValue,  $font-size-html-px) {
  
    $rem: ($sizeValue / $font-size-html-px) + rem;     
    @return $rem;

  } 
  @function pow($x, $n) {
    $ret: 1;
      
    @if $n >= 0 {
      @for $i from 1 through $n {
        $ret: $ret * $x;
      } 
    } @else {
      @for $i from $n to 0 {
        $ret: $ret / $x;
      }
    }
    
    @return $ret;
  }

  @function to-fixed($float, $digits: 2) {
    $sass-precision: 5;
    
    @if $digits > $sass-precision {
      @warn "Sass sets default precision to #{$sass-precision} digits, and there is no way to change that for now."
      + "The returned number will have #{$sass-precision} digits, even if you asked for `#{$digits}`."
      + "See https://github.com/sass/sass/issues/1122 for further informations.";
    }
    
    $pow: pow(10, $digits);
    @return round($float * $pow) / $pow;
  }

  $basic-size-width:1920;


  @mixin max-width-vw ($width){
    $widthVW: to-fixed(($width / $basic-size-width) * 100);
    max-width: $widthVW + vw;
  }	  

  @function vw ($width){
    $widthVW: to-fixed(($width / $basic-size-width) * 100) + vw;
    @return $widthVW;
  }	  

  @function procent ($width){
    $widthPR: to-fixed(($width / $basic-size-width) * 100)#{'%'}; 
    @return $widthPR;
  }	  

  @function procentVal ($width, $procent ){
    $widthPR: to-fixed(($procent / 100) * $width); 
    @return $widthPR;
  }	  

  @function vwmin ($width){
    $widthVW: to-fixed(($width / $basic-size-width) * 100) + vmin;
    @return $widthVW;
  }	  
// Body
$body-bg: #fff;

// Typography
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');

$font-family-h: 'Marcellus', serif;
$font-family: 'Oswald', sans-serif;
$font-size-html: 62.5%;
$font-size-html-1600: 60.5%;
$font-size-html-1440: 58.5%;
$font-size-html-1366:56.5%;
$font-size-html-1280: 54.5%;
$font-size-html-1152 : 52.5%;
$font-size-html-1024: 50.5%;
$font-size-html-640: 49.5%;
$font-size-html-414: 47.5%;
$font-size-html-px: 10;
$line-height-base: 1.2em;

@media screen and (max-width: 1440px) { 
  //$font-size-html-px: 16;
  //$font-size-html: 52.5%;
}

@mixin font-size-rem($sizeValue: 10) {
	font-size: $sizeValue + px;
    font-size: ($sizeValue / $font-size-html-px) + rem;    
  }

  @function rem($sizeValue,  $font-size-html-px) {
  
    $rem: ($sizeValue / $font-size-html-px) + rem;     
    @return $rem;

  } 
  @function pow($x, $n) {
    $ret: 1;
      
    @if $n >= 0 {
      @for $i from 1 through $n {
        $ret: $ret * $x;
      } 
    } @else {
      @for $i from $n to 0 {
        $ret: $ret / $x;
      }
    }
    
    @return $ret;
  }

  @function to-fixed($float, $digits: 2) {
    $sass-precision: 5;
    
    @if $digits > $sass-precision {
      @warn "Sass sets default precision to #{$sass-precision} digits, and there is no way to change that for now."
      + "The returned number will have #{$sass-precision} digits, even if you asked for `#{$digits}`."
      + "See https://github.com/sass/sass/issues/1122 for further informations.";
    }
    
    $pow: pow(10, $digits);
    @return round($float * $pow) / $pow;
  }

  $basic-size-width:1366;


  @mixin max-width-vw ($width){
    $widthVW: to-fixed(($width / $basic-size-width) * 100);
    max-width: $widthVW + vw;
  }	  

  @function vw ($width){
    $widthVW: to-fixed(($width / $basic-size-width) * 100) + vw;
    @return $widthVW;
  }	  

  @function procent ($width){
    $widthPR: to-fixed(($width / $basic-size-width) * 100)#{'%'}; 
    @return $widthPR;
  }	  

  @function procentVal ($width, $procent ){
    $widthPR: to-fixed(($procent / 100) * $width); 
    @return $widthPR;
  }	  

  @function vwmin ($width){
    $widthVW: to-fixed(($width / $basic-size-width) * 100) + vmin;
    @return $widthVW;
  }	  
// width

// pre-Colors
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$info: #17A2B8;
$info-dark: #127e8e;

$font-color-h:#323232;
$font-weight-regular:400;
$font-weight-medium:500;
$font-weight-semi:600;
$font-weight-bold:700;

//color-site



$beige:#E8D2B2;
$gold:#C9A26B;
$grey-light:#EEEEEE;
$grey-dark:#323232;
$black:#000000;
$white:#FFFFFF;
$blue-light: #586A93;
$blue: #354671;
$grey-dark-rgba:rgba(92, 98, 117, 0.4);


.color-gold{
	color: $gold;
}
.color-blue{
	color: $blue;
}
.color-beige{
	color: $beige;
}
.color-blue-light{
	color: $blue-light;
}

.grey-light{
  color:$grey-light !important;
}

.grey-dark{
  color:$grey-dark !important;
}


.bg-grey-light{
  background-color:$grey-light !important;
}

.bg-grey{
  background-color:$grey-dark !important;
}
.bg-yellow{
background: linear-gradient(#ffcc01 0%, #d9a002 100%);


}


//other
$border-radius:60px;
$popup-box-border-radius:15px;

//input
$box-shadow-input: 0px 8px 15px #5C627526;
$border-radius-input: 60px;
//$height-input: 7.14vh;
$height-input: auto;
$border-radius-input: 60px;
//$min-height-input: 50px;
$min-height-input: 40px;
$font-size-input: 22px;
$line-height-input: 1.2em;
$padding-input-top: 2.5vh;
$padding-input-left:  2.1vw;


