.top-bar{
	width: 100%;
	max-width: 100%;
}
.navbar-default,
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    background-color: transparent;
	border:none;
	padding:0;
	margin:0;
}

.navbar{
	&.container, .container {
		margin-left:auto;
		margin-right:auto;
	}
	&.container-fluid{
		margin:0;
	}
}
.container{
	padding: 0;
	box-sizing:border-box;
	&:before,
	&:after{
	content:none;
	}
} 
.navbar.container{ 
	margin: 0;
}

*{	
	box-sizing:border-box;
}
.row {
    margin-right:0;
    margin-left:0;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{
padding:0;
}
h1,h2,h3,h4,h5,h6{
	margin-left:0;
	font-family: $font-family-h;
}
.footer {
    margin: 0px;
    padding: 0px;
    border-top: none;
}
.form-control {
    display: block;
    width: 100%;
    padding: $padding-input-top $padding-input-left;
    
	font-size: $font-size-input;
	line-height: $line-height-input;
    color: $grey-dark;
    background-color: #fff;
    background-image: none;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	border:none;

	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: $box-shadow-input;
	border-radius: $border-radius-input;
	opacity: 1;
	height: $height-input;
	min-height:$min-height-input;
	&:hover, &:focus{
		color: $grey-dark;
		background-color: $grey-light;
		font-size: $font-size-input;

	}
}

form .panel-body,
form .panel {
    margin-bottom: 0px;
    background-color: transparent;
    border:none;
    box-shadow: none;
	padding:0;
}
.btn.icon-before .glyphicon, .btn .glyphicon.icon-before{
	display:none;
}
.btn:hover, .btn:focus, .btn:visited, .btn.focus, .btn-success:focus, .btn-success:hover{
	background-color:transparent;
	border:none;
}
.btn-success{
	background-color:transparent;
	border:none;
	
}
.dropdown-menu{
	border-radius:0;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}
.row{
	width: 100%;
}